@import "Colors";

/*
value         |0px     600px    960px    1280px   1920px
key           |xs      sm       md       lg       xl       tv-ticker
screen width  |--------|--------|--------|--------|-------->
range         |   xs   |   sm   |   md   |   lg   |   xl   |

font-size     |   15   |   15   |   20   |   20   |   25   |   30
h1            |   30   |   30   |   60   |   60   |   75   |   90
h2            |   30   |   30   |   40   |   40   |   50   |   60
*/

@mixin font-sizes($font-size) {
  body {
    font-size: $font-size;
  }
  h1 {
    font-size: $font-size * 3;
  }
  h2 {
    font-size: $font-size * 2;
  }
}

// >= xl
@include font-sizes(25px);
// < xl (1920px)
// < lg (1280px)
@media screen and (max-width: 1920px - 1) {
  @include font-sizes(20px);
}
// < md (960px)
// < sm (600px)
@media screen and (max-width: 960px - 1) {
  $font-size: 15px;
  body {
    font-size: $font-size;
  }
  h1 {
    font-size: $font-size * 2;
  }
  h2 {
    font-size: $font-size * 2;
  }
}

* {
  font-family: 'Source Sans Pro', sans-serif !important;
}

body {
  color: $color-dark;
  min-width: 320px;
}

h1, h2 {
  font-weight: bold;
  position: relative;
  margin-bottom: -5px;

  i {
    text-transform: lowercase;
    display: block;
    font-weight: bold;
    font-size: 50%;
    margin-bottom: 25px;
  }
}

i, highlight {
  color: $color-n4;
}
n {
  font-weight: normal;
}
