#news {
    table {
        img {
            width: 100px;
        }
    }
}
#news-upload {
    .character-counter {
        color: rgba(0, 0, 0, 0.54);
        margin: 0;
        font-size: 0.75rem;
        margin-top: 3px;
        text-align: left;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.66;
        letter-spacing: 0.03333em;
        text-align: right;
    }
}