@import "../Colors";

#news-view {
    background-color: $color-dark;


    // For Admins
    &.cursor-none {
        cursor: none !important;
    }
    #admin-navbar {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 53px;
    }
}
