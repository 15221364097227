.login-container {
    text-align: center;
}

.login {
    background: #fff;
    box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
    border-radius: 2px;
    width: 320px;
    display: inline-block;
    margin-top: 30px;
    vertical-align: middle;
    position: relative;    
    padding: 35px;
}