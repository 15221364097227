@import "../Colors";

#admin {
    margin-top: 65px;

    .fab {
        margin-left: 15px;
    }

    .fab-add {
        position: fixed;
        bottom: 15px;
        right: 15px;
    }

    .cropper-view-box {
        outline-color: $color-n4;
    }
    .cropper-point, .cropper-line {
        background-color: $color-n4;
    }
    .img-preview-container {
        overflow: hidden;
        position: relative;
        background-color: $color-dark;
        width: 100%;
        height: 100%;
        .img-preview {
            overflow: hidden;
            width: 100%;
            height: 100%;
            .cropper-img {
                img {
                    width: 100%;
                }
            }
        }
        .preview16to9 {
            position: absolute;
            top: 12.5%;
            width: 100%;
            height: 75%;
            z-index: 1;
            border: 2px solid $color-n4;
            border-left: 0;
            border-right: 0;
            box-shadow: 0 0 200px 200px rgba(0, 0, 0, 0.25);
        }
    }
}

.btn-primary, .btn-primary:hover, .btn-primary:active {
    background-color: $color-n4 !important;
    border-color: $color-n4 !important;
}