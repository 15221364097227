@import "../../Colors";

#sidebar {
    display: flex;
    flex-direction: column;

    background-color: white;
    height: 100vh;

    section {
        position: relative;

        display: flex;
        align-items: center;

        height: 250px;
        padding: 30px 40px;
        overflow: hidden;

        // >= lg
        @media screen and (min-width: 1280px) {
            height: 25vh;
            min-height: 200px;
        }

        &#menu {
            display: block;
            align-items: normal;

            flex-grow: 1;
            overflow-x: auto;
            .cantina-band {
                height: 160px;
                background: white url("img/menu.png") no-repeat top center;
                background-size: contain;
            }
            // < lg
            @media screen and (max-width: 1279px) {
                height: auto;
            }
        }

        &#weather {
            justify-content: space-around;
            background-color: $color-light;

            &.coming-soon {
                justify-content: normal;
                align-items: normal;
            }

            .day {
                align-items: center;
                text-align: center;
                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                i {
                    text-transform: lowercase;
                    display: block;
                    // < 1000 and >= lg
                    @media screen and (max-height:999px) and (min-width:1280px) {
                        display: none;
                    }
                }
            }

            .icon {
                img {
                    max-width: 90px;
                    max-height: 50px;
                    margin: 15px 0;
                }
            }

            .row {
                display: contents;
            }

            p {
                margin-bottom: 0;
            }

            .today p:first-child {
                font-weight: bold;
            }

            .icon-04d {
                background: url("img/weather.png") no-repeat center center;
                width: 75px;
                height: 75px;
                margin: auto;
            }
            // < 600 and > lg
            @media screen and (max-height: 599px) and (min-width:1280px) {
                display: none;
            }
        }

        &#appointments {
            background: $color-n4 url("img/appointments.png") no-repeat top 30px left 40px;
            color: white;

            &.today {
                background: $color-red url("img/appointments.png") no-repeat top 30px left 40px;
            }

            #header {
                text-align: right;
            }
            #footer {
                position: absolute;
                bottom: 30px;
            }
        }

        &#datetime {
            position: relative;
            background: $color-n4;
            color: white;
            #analogClock {
                $clock-color: white;
                $pointer-thickness: 3px;
                $pointer-hour-length: 15%;
                $pointer-minute-length: $pointer-hour-length*2;

                background: url("svg/date-time/clock_bg.svg") no-repeat center center;
                width: 120px;
                height: 120px;
                position: absolute;
                top: 50%;
                margin: -60px auto;

                &:after {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: $pointer-thickness;
                    height: $pointer-thickness;
                    margin: (-$pointer-thickness/2) 0 0 (-$pointer-thickness/2);
                    background: $clock-color;
                    border-radius: ($pointer-thickness/2);
                    content: "";
                }

                #hour, #minute {
                    background: $clock-color;
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 50%;
                    left: 50%;
                }

                #hour {
                    margin: (-$pointer-thickness/2) 0 (-$pointer-thickness/2) (-$pointer-hour-length);
                    padding: ($pointer-thickness/2) 0 ($pointer-thickness/2) ($pointer-hour-length);
                    transform-origin: 100% 50%;
                    border-radius: ($pointer-thickness/2) 0 0 ($pointer-thickness/2);
                }

                #minute {
                    margin: (-$pointer-minute-length) (-$pointer-thickness/2) 0;
                    padding: ($pointer-minute-length) ($pointer-thickness/2) 0;
                    transform-origin: 50% 100%;
                    border-radius: ($pointer-thickness/2) ($pointer-thickness/2) 0 0;
                }
            }
            .datetime {
                width: 100%;
                text-align: right;
                h2 {
                    padding-bottom: 10%;
                }
                p {
                    margin: 0;
                }
            }
            // < 800
            @media screen and (max-height:799px) {
                display: none;
            }
            // < lg
            @media screen and (max-width: 1279px) {
                display: none;
            }
        }
    }
}
    