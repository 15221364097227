@import "../../Colors";

.nav-link {
    cursor: pointer;
}

.headerlogo {
    height: 27px;
    width: 69px;
    background-size: cover;
    background-image: url('n4-logo.png');
}

.navbar-dark .navbar-nav {
    font: 16px "Source Sans Pro", sans-serif, Helvetica neue, Roboto, Ubuntu, Arial;
    .nav-item {
        margin: 0 20px;
        .nav-link {
            color: #fff;
        }
        &.active > .nav-link, .nav-link.show, .nav-link.active {
            color: $color-n4;
            font-style: italic;
            font-weight: bold;
        }
    }
}
