@import "../../Colors";
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

#news-ticker {
    $slick-dot-color: white;
    $slick-dot-color-active: $slick-dot-color;

    // Sonderfall Ticker auf TV
    // >= TV (1920x1079)
    @media screen and (min-width: 1920px) and (min-height: 1079px) {
        $font-size: 30px;
        font-size: $font-size;
        h1 {
            font-size: $font-size * 3;
        }
        h2 {
            font-size: $font-size * 2;
        }
    }

    // (1440x1080)
    // 4.3
    // 1.33
    //height: 75vw;

    // (1920x1080)
    // 16:9
    // 1.78
    //height: 56.25vw;

    position: relative;
    color: white;

    .slick-dots {
        //bottom: 30px;
        li {
            button {
                &:before {
                    color: $slick-dot-color;
                }
            }
            &.slick-active button:before {
                color: $slick-dot-color-active;
            }
        }
    }

    .header {
        position: absolute;
        z-index: 1;
        width: 100%;
        margin-top: 30px;

        .clock {
            color: white;
            margin-left: 40px;

            time {
                margin-right: 10px;
            }

            svg#minutes {
                width: 62px;
                height: 17px;
                fill: white;
                margin-right: 10px;

                & :not(.active) {
                    fill-opacity: 0.4;
                }
            }
        }

        .logo {
            &.n4news{
                background: url("img/n4news.png") no-repeat;
            }
            &.plansoftware{
                background: url("img/plansoftware.png") no-repeat;
                width: 237px;
            }
            background: url("img/n4news.png") no-repeat;
            height: 70px;
            width: 135px;
            margin-right: 40px;
            float: right;
        }
        // < lg
        @media screen and (max-width: 1280px -1) {
            display: none;
        }
    }

    .news {
        display: flex;
        flex-direction: column;

        // < sm
        height: 150vw;

        // >= sm
        @media screen and (min-width: 600px) {
            height: 75vh;
            min-height: 480px; // 320 x 568 is smallest
        }

        // >= lg
        @media screen and (min-width: 1280px) {
            height: 100vh;
            max-height: none;
        }

        .content {
            position: relative;

            // Full height
            flex-grow: 1;

            // < sm
            height: 75vw;

            // >= sm
            @media screen and (min-width: 600px) {
                height: 75%;
            }

            padding: 30px 40px;
            overflow: hidden;

            .background {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                max-height: 75vw; // 4:3

                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                z-index: 0;

                // < sm
                /*@media screen and (max-width: 599px) {
                    background-size: contain;
                }*/

                .gardient {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.5) 100%);
                    z-index: 1;
                }
            }
        }

        .footer {
            display: flex;

            flex-direction: column;
            justify-content: space-between;

            // < sm
            height: 75vw;

            // >= sm
            @media screen and (min-width: 600px) {
                height: 250px;
                min-height: 200px;
            }

            // >= lg
            @media screen and (min-width: 1280px) {
                height: 25%;
                min-height: 200px;
            }

            padding: 30px 40px;
            overflow: hidden;

            background: $color-dark;
            h1, h2 {
                margin-top: -10px;
            }
        }

        .logo {
            position: relative;
            height: 25%;

            img, h2 {
                position: absolute;
                max-height: 50%;
                bottom: 0;
                background-color: rgba(255, 255, 255, 0.4);
                padding: 20px 40px;
            }
        }

        &#coming-soon-view {
            &.n4news {
                background: url("img/n4news.png") center center no-repeat;
            }
            &.plansoftware {
                background: url("img/plansoftware.png") center center no-repeat;
            }

            position: relative;
            .footer {
                i {
                    position: absolute;
                    bottom: 30px;
                    right: 40px;
                    color: white;
                }
            }
        }

        &#default-news-view {
            .background {
                .gardient {
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);
                }
            }
        }

        &#graduation-news-view {
            .content {
                .background {
                    background-image: url('img/graduation.png');
                    filter: opacity(0.50);
                }

                display: flex;
                align-items: center;
                justify-content: center;

                #graduation-news-details {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    position: relative;
                    background-color: rgba(59, 59, 60, 0.87);

                    min-height: 50%;
                    min-width: 50%;
                    padding: 30px 40px;

                    z-index: 1;
                    #team-emblem {
                        position: absolute;
                        bottom: 30px;
                        right: 40px;
                        max-width: 130px;
                    }
                }

            }
        }

        &#greeting-news-view {
            .content {
                padding: 0;
                .background {
                    background-image: url('img/greeting.png');
                }

                h1 {
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 0;
                    padding: 30px 40px;
                }
            }
        }

        &#new-colleague-news-view {
            &.men {
                background: $color-dark url("img/men.png") no-repeat left bottom;
                background-size: auto 60%;
            }

            &.women {
                background: $color-dark url("img/women.png") no-repeat left bottom;
                background-size: auto 60%;
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                #colleague-details {
                    padding-left: 33%;

                    ul {
                        padding-left: 0;
                        li {
                            line-height: 60px;
                            padding-left: 60px;
                            list-style: none;
                            background-repeat: no-repeat;
                            &.location {
                                background: url('svg/location.svg') 5px center no-repeat;
                            }
                            &.begin {
                                background: url('svg/begin.svg') left center no-repeat;
                            }
                            &.job, &.member {
                                background: url('svg/job.svg') 1px center no-repeat;
                            }
                        }
                    }
                }

                #team-emblem {
                    max-width: 130px;
                }
            }
        }

        &#project-completion-news-view {
            .content {
                .background {
                    background-image: url('img/project-completion.png');
                }
            }
            .footer {
                #team-emblem {
                    position: absolute;
                    right: 40px;
                    max-height: 100px;
                }
                b {
                    color: $color-n4;
                }
            }
        }

        &#rapid-tech-talk-news-view {
            background: url('svg/RTT.svg') no-repeat  center bottom;
            .content {
                display: flex;
                flex-direction: column;

                #date {
                    display: flex;
                    flex-direction: column-reverse;

                    height: 25%;
                    padding-bottom: 20px;

                    i {
                        font-size: 100%;
                        display: inline-block;
                        margin-bottom: 0;
                    }
                }
                #themes {
                    color: white;

                    display: flex;
                    flex-wrap: wrap;

                    list-style: none;
                    padding: 0;
                    padding-top: 20px;
                    margin: 0;
                    .theme {
                        flex-basis: 100%;
                        flex-shrink: 1;
                        b {
                            display: inline-block;
                            color: $color-n4;
                            width: 25%;
                        }
                        @media screen and (max-width: 1279px) {
                            flex-basis: 100%;
                        }
                    }
                }
            }
            .background {
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 0;
                overflow: hidden;
                b {
                    position: relative;
                    color: #4a4a4b;
                    font-size: 47vw;
                    bottom: -5%;
                    left: -5%;
                }
            }
        }

        &#appointment-news-view {
            .background {
                background-image: url('img/schedule.png');
            }
            .footer {
                b {
                    color: $color-n4;
                }
                ul {
                    padding-left: 0;
                    li {
                        line-height: 60px;
                        padding-left: 60px;
                        list-style: none;
                        background-repeat: no-repeat;
                        &.location {
                            background: url('svg/location.svg') 5px center no-repeat;
                        }
                        &.begin {
                            background: url('svg/begin.svg') left center no-repeat;
                        }
                        &.job, &.member {
                            background: url('svg/job.svg') 1px center no-repeat;
                        }
                    }
                }
            }
        }
    }
}

